<template>
<v-app-bar app>
    <!-- <v-btn
      v-for="link in links"
      :key="link.label"
      :to="{ name: link.routerName }"
      class="navigation-link"
      depressed
      exact
      exact-active-class="link-active">
      {{ link.label }}
    </v-btn> -->

    <v-spacer></v-spacer>
    <v-btn @click="signout">Odjavi se ({{ name }})</v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    links: [
      // { label: 'Pocetna', routerName: 'dashboard' },
      { label: 'Dražbe', routerName: 'items' }
    ],
    activeLink: ''
  }),
  computed: {
    ...mapState('auth', ['user']),
    name() {
      return this.user.firstName;
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    signout () {
      this.logout();
      this.$router.push('/login')
    },
    isActive (routerName) {
      const currentRoute = this.activeLink
        ? this.activeLink : this.$router.currentRoute.name
      return routerName === currentRoute
    }
  }
}
</script>

<style lang="scss">
a.navigation-link {
  text-decoration: none;
  margin-right: 0.5rem;
}

.link-active {
  &:before { background-color: grey !important; }
}
</style>
