import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const opts = {
  icons: { iconfont: 'mdi' }
}

export default new Vuetify(opts)
