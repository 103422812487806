<template>
  <div class="admin">
    <navigation/>
    <router-view/>
  </div>
</template>

<script>
import Navigation from './common/Navigation'

export default {
  name: 'admin',
  metaInfo: () => ({ title: 'Admin page' }),
  components: { Navigation }
}
</script>

<style>
/* html,
body {
    height: 100% !important;
}
body {
    min-height: 100% !important;
}
.container {
  padding: 0px !important
} */
.admin {
  padding-top: 2rem;
  height: 100%;
}
</style>
