import * as actions from './actions';
import * as mutations from './mutations';
import api from '../../../api';

const state = {
  user: getUser() || null
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};

function getUser() {
  const data = localStorage.getItem('user');
  api.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    return JSON.parse(data);
  } catch (_) {
    return data;
  }
}
