import * as filters from '../common/filters';
import App from './App.vue'
import each from 'lodash/each';
import Meta from 'vue-meta'
import router from './router';
import store from './store'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false
Vue.use(Meta);
each(filters, (fn, name) => Vue.filter(name, fn));

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')
