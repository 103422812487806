import { differenceInDays, format } from 'date-fns';
import numeral from 'numeral';

const dateFormatLong = 'dd.MM.yyyy. HH:mm';

const formatCurrency = value => {
  return `${formatNumber(value)} €`;
};

const formatNumber = value => {
  return `${numeral(value).format('0,0')}`;
};

const formatDate = (date, dateFormat = dateFormatLong) => format(date, dateFormat);

const daysDistance = futureDate => {
  return differenceInDays(futureDate, new Date());
};

export {
  daysDistance, formatCurrency, formatDate, formatNumber
};
