import api from '../../../api'

const login = async ({ commit }, { email, password }) => {
  const result = await api.post('/login', { email, password });
  const { token, user } = result.data;
  commit('login', user);
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  api.defaults.headers.authorization = `Bearer ${token}`
}

const logout = async ({ commit }) => {
  commit('logout');
  localStorage.clear()
  api.defaults.headers.authorization = null;
}

export { login, logout };
