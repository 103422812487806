<template>
  <div class="items-page">
    <h1 class="text-center">Dražbe</h1>
    <div class="content-wrapper mt-5">
      <v-dialog v-model="dialog" width="900px" scrollable>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ dialogData.field }}</span>
          </v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text class="dialog-text text-justify">{{ dialogData.text }}</v-card-text>
          <v-divider class="mb-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog=false" dark color="green darken-1">
              Zatvori
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        @click="syncItems"
        :disabled="isLoading"
        width="320"
        class="mb-2 ml-4"
        color="primary">
        Dohvati zadnje podatke s FINE
      </v-btn>
      <div v-if="items.length" class="mb-6 ml-4 font-italic">
        Podaci su zadnji put osvjezeni: 
        {{ new Date(items[0].createdAt) | formatDate }}
      </div>
      <div class="mr-4 text-right">
        Korisni linkovi: 
        <a class="mr-4 ml-1" href="https://katastar.hr/#/" target="_blank">katastar.hr</a>
        <a href="https://oss.uredjenazemlja.hr/public/lrServices.jsp?action=publicLdbExtract" target="_blank">
          Zemljisnik
        </a>
      </div>
      <div v-if="isLoading" class="d-flex justify-center mt-12">
        <v-progress-circular :width="4" color="primary" indeterminate />
      </div>
      <template v-else>
        <v-data-table
          :footer-props="{ itemsPerPageOptions: [50, 100, 200, -1] }"
          :headers="headers"
          :items="items"
          :items-per-page="100"
          :search="search"
          height="70vh"
          class="elevation-1"
          fixed-header
          must-sort
          sort-by="brojDrazbe"
          sortDesc>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pronađi..."
              class="search-field mx-4 pt-6" />
          </template>
          <template v-slot:[`item.poslovniBrojSpisa`]="{ item }">
            <a href="https://ponip.fina.hr/ocevidnik-web/pretrazivanje/nekretnina" target="_blank">
              {{ item.poslovniBrojSpisa }}
            </a>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="copyText(item.poslovniBrojSpisa)"
                  class="ml-2"
                  color="primary"
                  size="14">
                  mdi-content-copy
                </v-icon>
              </template>
              <span>Kopiraj poslovni broj spisa</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.idNadmetanja`]="{ item }">
            <a href="https://ponip.fina.hr/ocevidnik-web/pretrazivanje/nekretnina" target="_blank">
              {{ item.idNadmetanja }}
            </a>
          </template>
          <template v-slot:[`item.iznosDrazbenogKoraka`]="{ item }">
            {{ item.iznosDrazbenogKoraka | formatCurrency }}
          </template>
          <template v-slot:[`item.brojDrazbe`]="{ item }">
            {{ item.oznakaEjd }}
          </template>
          <template v-slot:[`item.iznosJamcevine`]="{ item }">
            <span class="blue--text text--darken-2">
              <strong>{{ item.iznosJamcevine | formatCurrency }}</strong>
            </span>
          </template>
          <template v-slot:[`item.minZakonskaProdajnaCijena`]="{ item }">
            {{ item.minZakonskaProdajnaCijena | formatCurrency }}
          </template>
          <template v-slot:[`item.opis`]="{ item }">
            <template v-if="item.opis.length > 200">
              {{ item.opis.substring(0, 200) }}...
              <span @click="setDialogData('Opis', item.opis)" class="more-details ml-1"> 
                Više detalja
              </span>
            </template>
            <span v-else>{{ item.opis }}</span>
          </template>
          <template v-slot:[`item.napomenaUzDetaljeProdaje`]="{ item }">
            <template v-if="item.napomenaUzDetaljeProdaje.length > 150">
              {{ item.napomenaUzDetaljeProdaje.substring(0, 150) }}...
              <span
                @click="setDialogData('Detalji prodaje', item.napomenaUzDetaljeProdaje)"
                class="more-details ml-1"> 
                Više detalja
              </span>
            </template>
            <span v-else>{{ item.napomenaUzDetaljeProdaje }}</span>
          </template>
          <template v-slot:[`item.napomenaUzUvjeteProdaje`]="{ item }">
            <template v-if="item.napomenaUzUvjeteProdaje.length > 150">
              {{ item.napomenaUzUvjeteProdaje.substring(0, 150) }}...
              <span
                @click="setDialogData('Uvjeti prodaje', item.napomenaUzUvjeteProdaje)"
                class="more-details ml-1"> 
                Više detalja
              </span>
            </template>
            <span v-else>{{ item.napomenaUzUvjeteProdaje }}</span>
          </template>
          <template v-slot:[`item.ostaliUvjetiProdaje`]="{ item }">
            <template v-if="item.ostaliUvjetiProdaje.length > 70">
              {{ item.ostaliUvjetiProdaje.substring(0, 70) }}...
              <span
                @click="setDialogData('Ostali uvjeti prodaje', item.ostaliUvjetiProdaje)"
                class="more-details ml-1"> 
                Više detalja
              </span>
            </template>
            <span v-else>{{ item.ostaliUvjetiProdaje }}</span>
          </template>
          <template v-slot:[`item.pocetakNadmetanja`]="{ item }">
            <div>{{ new Date(item.pocetakNadmetanja) | formatDate }}</div>
            <div>(Za {{ new Date(item.pocetakNadmetanja) | daysDistance }} dana)</div>
          </template>
          <template v-slot:[`item.zavrsetakNadmetanja`]="{ item }">
            {{ new Date(item.zavrsetakNadmetanja) | formatDate }}
          </template>
          <template v-slot:[`item.pocetnaCijenaNadmetanja`]="{ item }">
            <span class="red--text text--darken-2">
              <strong>{{ item.pocetnaCijenaNadmetanja | formatCurrency }}</strong>
            </span>
          </template>
          <template v-slot:[`item.utvrdjenaVrijednost`]="{ item }">
            <span class="green--text text--darken-3">
              <strong>{{ item.utvrdjenaVrijednost | formatCurrency }}</strong>
            </span>
          </template>
          <template v-slot:[`item.razgledavanje`]="{ item }">
            <template v-if="item.razgledavanje.length > 70">
              {{ item.razgledavanje.substring(0, 70) }}...
              <span
                @click="setDialogData('Razgledavanje', item.razgledavanje)"
                class="more-details ml-1"> 
                Više detalja
              </span>
            </template>
            <span v-else>{{ item.razgledavanje }}</span>
          </template>
          <template v-slot:[`item.rokPolaganjaKupovnine`]="{ item }">
            <template v-if="item.rokPolaganjaKupovnine.length > 70">
              {{ item.rokPolaganjaKupovnine.substring(0, 70) }}...
              <span
                @click="setDialogData('Rok za polaganje kupovnine', item.rokPolaganjaKupovnine)"
                class="more-details ml-1"> 
                Više detalja
              </span>
            </template>
            <span v-else>{{ item.rokPolaganjaKupovnine }}</span>
          </template>
        </v-data-table>

        <!-- <div class="box box-1">box 1</div>
        <div class="box box-2">box 2</div>
        <div class="box box-3">box 3</div> -->

      </template>
    </div>
    <div class="hidden-input" id="clipboardText">{{ clipboardText }}</div>
  </div>
</template>

<script>
import api from '../../api/fina';

export default {
  name: 'fina-items',
  data() {
    return {
      clipboardText: null,
      dialog: false,
      dialogData: {
        field: '',
        text: ''
      },
      items: [],
      isLoading: false,
      search: ''
    };
  },
  computed: {
    headers() {
      return [
        { text: 'Poslovni broj spisa', value: 'poslovniBrojSpisa', width: 150 },
        { text: 'Broj dražbe', value: 'brojDrazbe', width: 100 },
        { text: 'Početna cijena nadmetanja', value: 'pocetnaCijenaNadmetanja', width: 140 },
        { text: 'Utvrđena vrijednost', value: 'utvrdjenaVrijednost', width: 160 },
        { text: 'Jamčevina', value: 'iznosJamcevine', width: 150 },
        { text: 'Iznos koraka', value: 'iznosDrazbenogKoraka', width: 150 },
        { text: 'Opis', value: 'opis', width: 500, sortable: false },
        { text: 'Detalji prodaje', value: 'napomenaUzDetaljeProdaje', width: 300, sortable: false },
        { text: 'Uvjeti prodaje', value: 'napomenaUzUvjeteProdaje', width: 300, sortable: false },
        { text: 'Ostali uvjeti prodaje', value: 'ostaliUvjetiProdaje', width: 250, sortable: false },
        { text: 'Početak nadmetanja', value: 'pocetakNadmetanja', width: 160 },
        { text: 'Završetak nadmetanja', value: 'zavrsetakNadmetanja', width: 160 },
        { text: 'Rok za polaganje kupovnine', value: 'rokPolaganjaKupovnine', width: 200 },
        { text: 'Razgledavanje', value: 'razgledavanje', width: 200, sortable: false },
        { text: 'Nadležno tijelo', value: 'nadleznoTijelo', width: 200 },
        { text: 'ID nadmetanja', value: 'idNadmetanja', width: 120 }
        // { text: 'Min Zakonska Prodajna cijena', value: 'minZakonskaProdajnaCijena', width: 150 },
        // { text: 'Vrsta imovine', value: 'slozenostOpsegImovine' },
        // { text: 'Predmet prodaje', value: 'vrstaPredmetaProdaje' },
        // { text: 'stanjeNaDan', value: 'stanjeNaDan' },
        // { text: 'Uvjeti za jamcevinu', value: 'ostaliUvjetiZaJamcevinnu' },
        // { text: 'oznakaEjd', value: 'oznakaEjd' },
        // { text: 'pocetak', value: 'XXXXXXXXXX' },
        // { text: 'datumOdlukeOProdaji', value: 'datumOdlukeOProdaji' },
        // { text: 'mogucnostProduljenjaZa10Min', value: 'mogucnostProduljenjaZa10Min' },
        // { text: 'nacinProdaje', value: 'nacinProdaje' },
      ];
    }
  },
  methods: {
    copyText (value) {
      var range = document.createRange();
      this.clipboardText = value;
      const containerNode = document.getElementById('clipboardText');
      this.$nextTick(async () => {
        range.selectNode(containerNode);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
      });
    },
    async fetch() {
      this.isLoading = true;
      const items = await api.getItems();
      this.items = items;
      this.isLoading = false;
    },
    setDialogData(field, text) {
      this.dialogData.field = field;
      this.dialogData.text = text;
      this.dialog = true;
    },
    async syncItems() {
      this.isLoading = true;
      await api.syncItems();
      const items = await api.getItems();
      this.items = items;
      this.isLoading = false;
    }
  },
  created() {
    this.fetch();
  },
  components: {  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table tbody tr:nth-of-type(even) {
      background-color: #E3F2FD;
  }
  .v-data-table th {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    vertical-align: top;
    background: #FFEBEE !important;
  }
  .search-field {
    width: 320px;
  }
  table th + th { border-left:1px solid #dddddd; }
  table td + td { border-left:1px solid #dddddd; }
}

.dialog-text {
  font-size: 1rem;
}

.more-details {
  color: red;
  cursor: pointer;
}

.hidden-input {
  color: #fff;
  height: 1px;
  font-size: 1px;
}

// .items-page, .content-wrapper {
//   height: 100%;
//   min-height: 100%;
// 	display: flex;
// 	flex-direction: column;
// }

</style>
