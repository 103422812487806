<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-form @submit.prevent="authenticate">
            <v-card class="elevation-12">
              <v-toolbar dark color="#00bff3">
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text class="fields">
                <v-text-field
                  v-model="email"
                  name="email"
                  label="Email"
                  type="text"
                />
                <v-text-field
                  v-model="password"
                  name="password"
                  label="Password"
                  id="password"
                  type="password"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="login-button" type="submit">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
          <v-alert
            :value="invalidCredentials"
            color="#f96868"
            type="error"
          >
            You have entered wrong email or password!
          </v-alert>
          <v-card-text class="forgot-password text-lg-right">
            <router-link :to="{ name: 'ForgotPassword' }">Forgot password?</router-link>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: '',
      invalidCredentials: false
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async authenticate () {
      const { email, password } = this
      try {
        await this.login({ email: email.toLowerCase(), password })
        this.$router.push({ name: 'items' })
      } catch (error) {
        this.invalidCredentials = true
      }
    }
  }
}
</script>

<style lang="scss">
.fields input:-webkit-autofill {
  z-index: 1;
  box-shadow: 0 0 0 30px white inset;
}

.login-button {
  color: #fff !important;
  background-color: #00bff3 !important;
}

.forgot-password a {
  text-decoration: none;
  color: #757575;

  &:hover {
    text-decoration: underline;
  }
}
</style>
