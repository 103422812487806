import _get from 'lodash/get';
import api from './index';

const BASE_URL = '/item';

const getData = res => _get(res, 'data', {});

function getItems() {
  return api.get(BASE_URL).then(getData);
}

function syncItems() {
  return api.post(`${BASE_URL}/sync-items`).then(getData);
}

export default { getItems, syncItems };
