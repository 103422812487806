// import Dashboard from './components/Dashboard';
// import ForgotPassword from './components/auth/ForgotPassword';
import get from 'lodash/get';
import Home from './components';
import Items from './components/Items';
import Login from './components/auth/Login'
import NotFound from './components/common/NotFound';
// import ResetPassword from './components/auth/ResetPassword';
import Router from 'vue-router';
import store from './store';
import transform from 'lodash/transform';
import Vue from 'vue';

Vue.use(Router);

const parseParams = ({ params }) => {
  return transform(params, (acc, val, key) => (acc[key] = parseInt(val, 10)), {});
};
// Handle 404
const fallbackRoute = { path: '*', component: NotFound };

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/',
    component: Home,
    beforeEnter: requireAuth,
    meta: { auth: true },
    children: [
    // {
    //   path: '',
    //   name: 'dashboard',
    //   component: Dashboard,
    //   props: parseParams
    // },
    {
      path: '',
      name: 'items',
      component: Items,
      props: parseParams
    }]
  },
  fallbackRoute]
});

const isAuthenticated = () => {
  const isAuth = !!get(store.state, 'auth.user');
  return !!isAuth;
}

function requireAuth (to, _, next) {
  return isAuthenticated()
    ? next()
    : next({ path: '/login', query: { redirect: to.fullPath } });
}

export default router;
