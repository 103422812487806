var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"items-page"},[_c('h1',{staticClass:"text-center"},[_vm._v("Dražbe")]),_c('div',{staticClass:"content-wrapper mt-5"},[_c('v-dialog',{attrs:{"width":"900px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.dialogData.field))])]),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',{staticClass:"dialog-text text-justify"},[_vm._v(_vm._s(_vm.dialogData.text))]),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"green darken-1"},on:{"click":function($event){_vm.dialog=false}}},[_vm._v(" Zatvori ")])],1)],1)],1),_c('v-btn',{staticClass:"mb-2 ml-4",attrs:{"disabled":_vm.isLoading,"width":"320","color":"primary"},on:{"click":_vm.syncItems}},[_vm._v(" Dohvati zadnje podatke s FINE ")]),(_vm.items.length)?_c('div',{staticClass:"mb-6 ml-4 font-italic"},[_vm._v(" Podaci su zadnji put osvjezeni: "+_vm._s(_vm._f("formatDate")(new Date(_vm.items[0].createdAt)))+" ")]):_vm._e(),_vm._m(0),(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-center mt-12"},[_c('v-progress-circular',{attrs:{"width":4,"color":"primary","indeterminate":""}})],1):[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{ itemsPerPageOptions: [50, 100, 200, -1] },"headers":_vm.headers,"items":_vm.items,"items-per-page":100,"search":_vm.search,"height":"70vh","fixed-header":"","must-sort":"","sort-by":"brojDrazbe","sortDesc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"search-field mx-4 pt-6",attrs:{"append-icon":"mdi-magnify","label":"Pronađi..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.poslovniBrojSpisa",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"https://ponip.fina.hr/ocevidnik-web/pretrazivanje/nekretnina","target":"_blank"}},[_vm._v(" "+_vm._s(item.poslovniBrojSpisa)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","size":"14"},on:{"click":function($event){return _vm.copyText(item.poslovniBrojSpisa)}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Kopiraj poslovni broj spisa")])])]}},{key:"item.idNadmetanja",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"https://ponip.fina.hr/ocevidnik-web/pretrazivanje/nekretnina","target":"_blank"}},[_vm._v(" "+_vm._s(item.idNadmetanja)+" ")])]}},{key:"item.iznosDrazbenogKoraka",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.iznosDrazbenogKoraka))+" ")]}},{key:"item.brojDrazbe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.oznakaEjd)+" ")]}},{key:"item.iznosJamcevine",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"blue--text text--darken-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.iznosJamcevine)))])])]}},{key:"item.minZakonskaProdajnaCijena",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.minZakonskaProdajnaCijena))+" ")]}},{key:"item.opis",fn:function(ref){
var item = ref.item;
return [(item.opis.length > 200)?[_vm._v(" "+_vm._s(item.opis.substring(0, 200))+"... "),_c('span',{staticClass:"more-details ml-1",on:{"click":function($event){return _vm.setDialogData('Opis', item.opis)}}},[_vm._v(" Više detalja ")])]:_c('span',[_vm._v(_vm._s(item.opis))])]}},{key:"item.napomenaUzDetaljeProdaje",fn:function(ref){
var item = ref.item;
return [(item.napomenaUzDetaljeProdaje.length > 150)?[_vm._v(" "+_vm._s(item.napomenaUzDetaljeProdaje.substring(0, 150))+"... "),_c('span',{staticClass:"more-details ml-1",on:{"click":function($event){return _vm.setDialogData('Detalji prodaje', item.napomenaUzDetaljeProdaje)}}},[_vm._v(" Više detalja ")])]:_c('span',[_vm._v(_vm._s(item.napomenaUzDetaljeProdaje))])]}},{key:"item.napomenaUzUvjeteProdaje",fn:function(ref){
var item = ref.item;
return [(item.napomenaUzUvjeteProdaje.length > 150)?[_vm._v(" "+_vm._s(item.napomenaUzUvjeteProdaje.substring(0, 150))+"... "),_c('span',{staticClass:"more-details ml-1",on:{"click":function($event){return _vm.setDialogData('Uvjeti prodaje', item.napomenaUzUvjeteProdaje)}}},[_vm._v(" Više detalja ")])]:_c('span',[_vm._v(_vm._s(item.napomenaUzUvjeteProdaje))])]}},{key:"item.ostaliUvjetiProdaje",fn:function(ref){
var item = ref.item;
return [(item.ostaliUvjetiProdaje.length > 70)?[_vm._v(" "+_vm._s(item.ostaliUvjetiProdaje.substring(0, 70))+"... "),_c('span',{staticClass:"more-details ml-1",on:{"click":function($event){return _vm.setDialogData('Ostali uvjeti prodaje', item.ostaliUvjetiProdaje)}}},[_vm._v(" Više detalja ")])]:_c('span',[_vm._v(_vm._s(item.ostaliUvjetiProdaje))])]}},{key:"item.pocetakNadmetanja",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(new Date(item.pocetakNadmetanja))))]),_c('div',[_vm._v("(Za "+_vm._s(_vm._f("daysDistance")(new Date(item.pocetakNadmetanja)))+" dana)")])]}},{key:"item.zavrsetakNadmetanja",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(new Date(item.zavrsetakNadmetanja)))+" ")]}},{key:"item.pocetnaCijenaNadmetanja",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"red--text text--darken-2"},[_c('strong',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.pocetnaCijenaNadmetanja)))])])]}},{key:"item.utvrdjenaVrijednost",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"green--text text--darken-3"},[_c('strong',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.utvrdjenaVrijednost)))])])]}},{key:"item.razgledavanje",fn:function(ref){
var item = ref.item;
return [(item.razgledavanje.length > 70)?[_vm._v(" "+_vm._s(item.razgledavanje.substring(0, 70))+"... "),_c('span',{staticClass:"more-details ml-1",on:{"click":function($event){return _vm.setDialogData('Razgledavanje', item.razgledavanje)}}},[_vm._v(" Više detalja ")])]:_c('span',[_vm._v(_vm._s(item.razgledavanje))])]}},{key:"item.rokPolaganjaKupovnine",fn:function(ref){
var item = ref.item;
return [(item.rokPolaganjaKupovnine.length > 70)?[_vm._v(" "+_vm._s(item.rokPolaganjaKupovnine.substring(0, 70))+"... "),_c('span',{staticClass:"more-details ml-1",on:{"click":function($event){return _vm.setDialogData('Rok za polaganje kupovnine', item.rokPolaganjaKupovnine)}}},[_vm._v(" Više detalja ")])]:_c('span',[_vm._v(_vm._s(item.rokPolaganjaKupovnine))])]}}],null,true)})]],2),_c('div',{staticClass:"hidden-input",attrs:{"id":"clipboardText"}},[_vm._v(_vm._s(_vm.clipboardText))])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-4 text-right"},[_vm._v(" Korisni linkovi: "),_c('a',{staticClass:"mr-4 ml-1",attrs:{"href":"https://katastar.hr/#/","target":"_blank"}},[_vm._v("katastar.hr")]),_c('a',{attrs:{"href":"https://oss.uredjenazemlja.hr/public/lrServices.jsp?action=publicLdbExtract","target":"_blank"}},[_vm._v(" Zemljisnik ")])])}]

export { render, staticRenderFns }